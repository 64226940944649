@import url("https://fonts.googleapis.com/css2?family=Muli:wght@200;300;400;500;700;800;900&family=Catamaran:wght@100;300;400;500;700;800;900&family=Fira+Code:wght@300&family=Lato:wght@100;300;400;500;700;800;900&display=swap");


:root {
  --orange: rgb(233, 94, 7);
  --pink: #ec1111;
  --blue: linear-gradient(135deg,#3c6eff,#6fa7ff);
  --gradient: linear-gradient(97.3deg, var(--pink) 0%, var(--orange) 100%);
  --gradient-green: linear-gradient(135deg,#41bd56,#76e08f);
  --gradient-pink: linear-gradient(135deg,#ff0d90,#ff1ec3);
  --gradient-light: linear-gradient(90deg, rgb(227, 227, 218) 0%, rgb(197, 215, 218) 100%);
  --white: #ffffff;
  --green: #41bd56;
  --red: #df3312;
  --lightorange: #f69546;
  --black: #16191f;
  --highlight-font-typeface: "Catamaran";
  --heading-font-typeface: "Catamaran";
  --body-font-typeface: "Muli";
}

.blue {
  color: var(--blue) !important;
}

.bg-blue {
  background: var(--blue) !important;
}

.badge {
  /* background: var(--blue) !important; */
  font-weight: 300;
  min-width: 70px;
  padding: 4px 5px;
}

.price {
  font-family: var(--body-font-typeface);
  font-weight: 500;
}
body {
  /* background-color: #e8eef8; */
  /* background-color: rgb(25, 28, 31); */
  /* background: linear-gradient(90deg, rgb(227,227,218) 0%, rgb(197,215,218) 100%);
  color: #000000;  */

  /* background: rgba(0,0,0,0.9); */
  background: linear-gradient(90deg, rgb(45, 45, 45) 0%, rgb(8, 8, 8) 100%) !important;
  color: #fff;

  line-height: 1.3;
  font-family: var(--body-font-typeface);
  line-height: 1.3em;
  font-size: 0.85em;
  padding-bottom: 15px;
  height: 100vh;
  font-weight: 500;
  overflow: auto;
  /* text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased; */
  /* margin-top: 15px; */
}


.border-top {
  border-color: #333!important;
}

.position-relative {
  position: relative;
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus,
.btn:focus {
  outline: none !important;
  box-shadow: none !important;

}

.btn:active .btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none !important;
  box-shadow: none;
}

btn:focus,
a:focus {
  outline: none;
}

.navbar-toggler:focus {
  outline: none !important;
  box-shadow: none;
}

.text-orange {
  color: var(--orange);
}


div#wallet_details {
  /* position: absolute; */
  left: 0;
  right: 0;
  height: 0px;
  z-index: 99999;
  overflow: hidden;
  ;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  transition: height 0.25s ease-in;
}



div#wallet_details.show {
  display: block;
  height: 300px;
  transition: height 0.15s ease-out;
  overflow: auto;
}

div.main {
  /* padding: 2.5rem !important; */
  display: block;
}

.pointer {
  cursor: pointer;
}

.offcanvas-backdrop {
  opacity: 0.5;
}

.offcanvas.offcanvas-end.show {
  top: 50px;
}

.offcanvas.offcanvas-end {
  top: 50px;
}
.markdown h3, .markdown h4 {
  margin-top: 10px;
}
.markdown h4 {
  color: #336699;
}


.markdown ul {
  margin: 5px 25px;
}

.markdown ul li {
  list-style: disc;
}

.markdown b {
  color: #000;
}

.markdown img {
  max-width: 100%;
  /* border: 2px solid #fff; */
  /* border-radius: 0.25rem 0.25rem; */
  padding: 2px 2px;
  /* background-color: #fff; */
  margin: center center;
}

.markdown blockquote {
  border-left: 3px solid #999;
  padding: 15px 10px;
  margin: 1em 1em 1em 0em;
  margin-bottom: 1em;
  background: rgb(197, 215, 218);
  color: #000;

}

.markdown blockquote p {
  margin: 0px 0px;
  padding: 0px 0px;
}

.markdown pre {
  background: #000;
  color: #fff;
  border-left: 4px solid #ddd;
  padding: 14px 10px;
  font-family: "Fira Code";

  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;

  white-space: pre-wrap;
  /* css-3 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */



}

.markdown pre code {
  font-family: "Fira Code";
}

.markdown a {
  color: #000;
  text-decoration: underline;
}


.markdown .figure {
  /* background: linear-gradient(90deg, rgb(227,227,218) 0%, rgb(197,215,218) 100%); */

  border-left: 3px solid #ddd;
  /* border-radius: 0.25rem 0.25rem; */
  position: relative;
  margin: 0px auto;
  padding: 0px 20px;
}

.markdown .figure-caption {
  font-size: 1em;
  background: rgb(197, 215, 218);
  font-weight: 400;
  padding: 0px 15px;
  /* padding-top: 15px; */
  color: #000;
  padding: 15px 10px;
  padding-left: 30px;
  border-left: 3px solid #10873e;
  margin-left: -23px;
}

/* .markdown .figure-caption::before {
  content: '';
  position: absolute;
  background: #10873e;
  border: 3px solid #10873e;
  margin-left: -41px;
  border-radius: 100%;
  width: 20px; height: 20px;
} */

.btn-danger {
  background: var(--red)!important;
  border-color: var(--red)!important;
}

.alert-danger {
  background: var(--red)!important;
  color: #fff;
  font-weight: bold;
  border: 0px;
  border-radius: 0px;
  box-shadow: none;
  padding: 0px;
  margin: 0px;
  padding: 5px 10px;
}


.toast.bg-warning {
  background: var(--orange) !important;
}

.toast.bg-info {
  background: #444 !important;
}

.toast.bg-success {
  background: var(--green) !important;
}

.toast.bg-danger {
  background: var(--red) !important;
}

.toast-header {
  background: transparent !important;
  border-bottom: 0px;
}

.btn-close {
  box-shadow: none;
}



.logo {

  /* background: linear-gradient(74.84deg,#00C805 18.12%,#C3F53C 81.46%); */
  /* text-transform: uppercase;
  font-size: 3em;
  height: 1.5em;
  color: #00C805;
  background: rgba(255,255,255,0.5);
  
  display: inline-block;
  padding: 10px 25px;
  border-radius: 0.5rem 0.5rem;; */
  text-transform: uppercase;
  display: block;
  font-family: var(--body-font-typeface);
  font-weight: 100;

  font-size: 0.8em;
  position: relative;
  /* margin-top: 2em; */
  /* margin-bottom: 1em; */
  /* line-height: 30px; */
  font-family: var(--highlight-font-typeface);
  /* margin-left: 3em; */
  /* background: linear-gradient(97.3deg, #dd2476 0%, #ff512f 100%); */
  /* background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 400% 400%;
  animation: animategradient 3s ease infinite; */

  /* padding: 5px 20px; */
  /* background: #fff;
    color: #000; */
  /* margin-left: -15px!important; */
  /* border-radius: 1rem 1rem; */
}

/* body.light .logo {
  background: #000;
  color: #fff;
} */

/* .logo::before {
  content: "\00a0 ";
  border-radius: 0.3rem 0.3rem;
  display: inline-block;
  width: 100%;
  height: 30px;
  margin-right: 10px;
  background: #fff;
  z-index: -1;
} */

/* 
.logo::after {
  content: "\00a0 ";
  border-radius: 0.3rem 0.3rem;
  position: absolute;
  display: inline-block;
  width: 10px;
  height: 10px;
  top: 20px;
  left: 20px;
  margin-right: 10px;
  background: #ff512f;
} */

.dropdown-item {
  font-family: var(--body-font-typeface);
  line-height: 1em;
  font-size: 0.85em;
  /* font-weight: 100; */
  white-space: normal;
}

.form-control {
  font-family: var(--body-font-typeface);
  line-height: 1.2em;
  font-size: 1em;
}



a {
  color: #fff;
  text-decoration: underline;
}


a:hover {
  color: #fff;
}

.list-group-item {
  background-color: rgba(0, 0, 0, 0.8) !important;
  color: #fff;
}

.list-group-item.active {
  background-color: rgb(233, 86, 39) !important;
  border-color: rgb(233, 86, 39);
}


.navbar-nav .nav-link {
  margin-right: 10px;
  padding: 4px 10px;
  color: #fff!important;
}


/* .navbar-nav .nav-link:not(.dropdown), */
.navbar-nav .nav-link.active:not(.dropdown) {
  font-weight: 400;
  /* color: var(--pink) !important; */
  background: var(--gradient) !important;
  color: #fff!important;
  border-radius: 1rem 1rem;
  /* background: linear-gradient(#dd2476, #ff512f); */
  /* background-clip: text; */
  /* -webkit-text-fill-color: transparent; */
}


.navbar-nav .nav-link span {
  padding-top: 2px;
  float: right;
  margin-left: 2px;

}

.text-muted {
  color: #565055;
}

.nav-tabs {
  border: 0px !important;
}

.nav-tabs .nav-link {
  border-radius: 0em !important;
  font-family: "Quicksand";
  color: #1c1e21;
}

.bicon {
  position: absolute;
  left: 50%;
  margin-left: -27px;
  padding: 15px 15px;
  border-radius: 100%;
  width: 54px;
  top: -25px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  /* background: rgba(255,255,255,0.4)!important; */
  background: rgb(197, 215, 218) !important;
  border: 0px solid #000;
  border-radius: 0.25em 0.25rem;
  color: #000;
}

.nav-pills,
.nav-pills .nav-link {
  color: #fff;
  font-family: var(--body-font-typeface);
  border-radius: 0rem;
  box-shadow: none;
  /* font-size: 1.1em; */
  /* text-transform: uppercase; */
  font-weight: 600;
}


.highlight {
  /* font-family: var(--body-font-typeface); */
  font-family: var(--highlight-font-typeface);
  color: var(--orange);
  /* background: var(--orange);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 400% 400%;
  animation: animategradient 0.5s ease infinite; */

  /* -webkit-text-fill-color: linear-gradient(270deg, rgb(255, 115, 86) 0%, rgb(255, 89, 226) 25.52%, rgb(82, 221, 246) 50%, rgb(234, 223, 78) 76.04%, rgb(255, 115, 86) 100%) 0% 0% / 200% text; */
  /*   animation: 4s linear 0s infinite normal none running eEGgoN;

   background: linear-gradient(90.19deg, var(--orange) 39.29%, var(--pink) 65.9%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;  */

}

@media screen and (max-width: 800px) {
  .modal-content {
    width: 100%;
  }
}

#toast {
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 1rem;
  width: 320px;
  background: rgba(0, 0, 0, 0.9);
  color: #fff;
  font-weight: bold;
  z-index: 10;
  padding: 10px 15px;
  border: 1px solid #fff;
  font-family: "Quicksand";
}


#root {
  min-height: 100% !important;
}

.bg-dark {
  background-color: rgba(255, 255, 255, 0.1) !important;
  /* background-color: rgb(75,60,79)!important; */
}

.bg-green {
  background-color: rgb(233, 86, 39) !important
}

.bg-green h5 {
  color: #fff;
}

.navbar-toggler {
  border: 0px;
  box-shadow: none;
  font-size: 2em;
  padding: 0px;
  margin: 0px;
}

.btn {
  border-radius: 1rem 1rem;
  /* font-weight: 100; */
  box-shadow: none;
  /* border: 0px; */
  padding: 5px 24px;
  font-family: var(--heading-font-typeface);
  font-size: 1em;
  font-weight: 400 !important;
  transition: transform .1s;
}

.btn.large {
  padding: 12px 24px!important;
}

button {
  max-height: 100% !important;
}

.btn-lg,
.btn-group-lg>.btn {
  font-size: 15px;
}

.LazyLoad {
  opacity: 0;
  transition: all 1s ease-in-out;
}

.is-visible {
  opacity: 1;
}


ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.btn-dark {
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  box-shadow: none;
  border: 0px;

}

.btn-warning {
  background: var(--lightorange);
  color: #000;
  box-shadow: none;
  border: 0px;
}

.btn-primary {
  background: var(--orange);
  /* background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab); */
  background-size: 400% 400%;
  color: #fff;
  box-shadow: none;
  font-weight: 300 !important;
  border: 0px;

}


.btn-primary:hover {
  animation: animategradient 2s ease infinite;
}


.btn-outline-primary {
  border: 1px solid var(--orange)!important;
  background: transparent!important;
  color: var(--orange)!important
}

.btn-outline-primary:hover {
  border-color: var(--orange)!important;
  background: var(--orange)!important;
  color: #fff!important;
}


.btn-outline-secondary {
  border: 1px solid var(--blue)!important;
  background: transparent!important;
  color: var(--blue)!important;
}

.btn-outline-secondary:hover {
  border-color: var(--blue)!important;
  background: var(--blue)!important;
  color: #fff!important;
}

@keyframes animategradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}


.text-success {
  color: var(--green) !important;
}

.bg-success {
  background: var(--green) !important;
}

.btn-none {
  box-shadow: none;
  border: 0px;
  color: #565055;
}

.btn-none:hover {
  border: 0px;
  color: #000;
}

.btn-primary:hover {
  background-color: var(--orange) !important;
  color: #fff;
  box-shadow: none;
  border: 0px;
}

.accordion,
.accordion-item {
  color: #fff!important;
  border: 0px;
}

.accordion-button::after {
  filter: invert(1);
}

.accordion-button {
  background: #000;
  color: #fff;
  border: 0px !important;
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  background: #000;
  color: #fff;
  border: 0px;
  box-shadow: none !important;
}


.bg-info {
  background: #D4D9D2 !important;
}

/*
.bg-light {
  background-color: #ddd!important;
  background: linear-gradient(180deg, #F2F3F5 0%, rgba(238,238,238,1) 35%, #F2F3F5 100%);
  } */

button,
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: inset 0 -1px 0 #ddd;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}




::selection {
  background-color: rgb(197, 215, 218);
  color: #000;
}



#walletConnect {
  /* position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999; */
}

/* .bg-dark {
  background-color: rgb(25, 28, 31);
} */

/* .accordion {
  border: 0px!important;
  border-color: transparent!important;
}

.accordion-button {
  background-color: rgb(75,60,79)!important;
  color: #fff;
  border: 0px!important;
}
.accordian-header {
  border: 0px;
}

.accordion-button:not(.collapsed) {
  background-color: rgba(25, 28, 31);
  color: #fff;
  border: 0px;
}

.accordion-item {
  border-color: rgba(25, 28, 31, 0.9);
  border: 0px;
  
} 
.bg, .accordion-body {
  background: rgb(34,33,36);
  color: #fff;
  border: 0px;
}
*/


.App {}

.table {
  font-size: 0.9em;
}

.table tr td {
  padding: 15px 15px;
}

.navbar-brand,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 400;
  letter-spacing: -0.04em;
  font-family: var(--heading-font-typeface);
}

h1,
.h1 {
  font-weight: 300;
  /* color: var(--orange); */
}

h6,
.h6,
h6.accordion-header {
  font-weight: 500 !important;
}

.bg-image {

  z-index: -1;

  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  /* Add the blur effect */
  filter: blur(48px);
  -webkit-filter: blur(48px);
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* box-shadow: 0 1px 5px rgba(255,255,255,1); */

  opacity: 0.3;

  /* transition: opacity 0.3s linear 2s; */

  /* backdrop-filter: blur(4px); */


}





.nft-card {

  transition: transform .1s;
  /* border-radius: 1rem 1rem; */
  /* border-radius: 0.5rem 0.5rem; */
  padding: 0.3rem 0.3rem;
  background: rgba(0, 0, 0, 1) !important;
}

.nft-card .image {
  transition: transform .1s;
}

.nft-card:hover .image {
  transform: translateX(-50%) translateY(-50%);
  transform: scale(1.05);
}

.nft-card .card-title {
  /* position: absolute;
  background: rgba(28,29,34, 0.8);
  border-radius: 0.5rem 0.5rem;
  left: 0; right: 0;
  bottom: 10px; */
  margin: 10px 0px;
  color: #fff;
  /* font-weight: 800; */
  padding: 5px 15px;
  font-size: 1em;
}

.nft-card .card-title .price {
  font-size: 0.8em !important;
  font-weight: 100;
  padding-top: 2px;
  display: block;
  position: absolute;
  background: var(--black);
  top: 10px;
  right: 10px;
  border-radius: 0.3rem 0.3rem;
  padding: 5px 5px;
  /* width: 70px; */
  text-align: center;
}



.nft-card .card-body {
  /* color: #fff;
  z-index: 4;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-family: "Quicksand";
  background: linear-gradient(180deg, rgba(23, 19, 29,0) 0%,  rgb(23, 19, 29) 100%);

  background: linear-gradient(180deg, rgba(23, 19, 29,0), rgb(23, 19, 29));
  border-radius: 1rem 1rem;
  height: 50%;
  line-height: 50px;
  cursor: pointer; */
}

.nft-card .card-img-top {
  /* border-radius: 1rem 1rem; */
}

.nft-card .card-body .card-title {
  /* position: absolute;
  left: 0; right: 0;
  bottom: 20px; */
}


.image {
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;
  /* border: 4px solid #fff; */
  /* background-color: #fff; */
  cursor: pointer;
  /* height: 350px; */
  border-radius: 0.5rem 0.5rem;
}



.image img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  height: 100%;
  width: auto;
  min-width: 100%;
  border-color: #000;
  /* border-radius: 0.5rem 0.5rem; */
  /* background-image: url("../placeholder-square.jpeg") */
}



b,
strong,
.bold {
  font-weight: 700;
  /* color: #fff; */
}

.list-group-item {
  background: rgba(255, 255, 255, 0.4);
}

.bg-white-soft {
  background: rgba(0, 0, 0, 0.4) !important;
  color: #fff !important;
}

.breadcrumb {
  padding-left: 15px;
  padding-top: 10px;
  display: none;
}

.invert {
  filter: invert(100%)
}

.carousel-inner {
  margin: 0rem 5rem;
  width: auto;
  padding: 1rem 1rem;
}

.carousel-indicators {
  margin-bottom: 0em !important;
}

.carousel-control-prev,
.carousel-control-next {
  width: 5%;
}

/* .modal-content {
  background: #000;
}

.modal-content .btn-close {
  
} */
/* .modal-content {
  position: fixed;
  right: 0; top: 0; bottom: 0;
  height: 100%; overflow: auto;
  width: 450px;
  border-radius: 0rem;
  box-shadow: none;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
   -o-transform: translate3d(0%, 0, 0);
      transform: translate3d(0%, 0, 0);

}

.modal-body {
  overflow: auto;
  margin-bottom: 100px;
}

.modal-footer {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100px;
} */



/* .App {
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


/* .grid-container {
  columns: 4;
  
}

.grid-container>.grid-div {
  break-inside:avoid;
  margin-right: 5px;
}

@media screen and (max-width: 1200px) {
  .grid-container {
    columns:3;
    column-gap: 5px;
  }
}

@media screen and (max-width: 800px) {
  .grid-container {
    columns:2;
    column-gap: 5px;
  }
}

@media screen and (max-width: 350px) {
  .grid-container {
    columns:1;
    column-gap: 5px;
  }
} */


.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  z-index: 100;
  padding: 10px 20px;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  width: 250px;
  background-color: #fff;
}

#sidebar-wrapper {
  min-height: 100vh !important;
  width: 100vw;
  margin-left: -1rem;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
}






/* body {
  background: linear-gradient(90deg, rgb(227,227,218) 0%, rgb(197,215,218) 100%)!important;
  color: #000000!important; 
}
.bg-black {
  background: #fff!important;
  color: #000!important;
}

a, b { 
  color: #000!important;
}

.bg-white-soft {
  background: rgba(255,255,255,0.4)!important;
  color: #000!important;
}

.btn {
  color: #fff!important;
} */

.dropdown .dropdown-menu {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;

  max-height: 0;
  display: block;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.15s ease-out;

}

.dropdown.show .dropdown-menu {
  max-height: 300px;
  opacity: 1;
  overflow: auto;
  transition: max-height 0.15s ease-out;
  padding: 0px;
}

.navbar-nav .dropdown-menu {
  padding: 0px;
  margin: 0px;
  margin-top: 15px;
  border: 0px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  position: absolute;
  transition: visibility 0.15s ease-out;
}

.text-primary {
  color: var(--blue)!important;
}
a.dropdown-item {
  padding: 10px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

a.dropdown-item:focus,
a.dropdown-item.active {
  background-color: var(--orange) !important;
  color: #fff !important;
}

body.light {
  /* background: linear-gradient(90deg, #eaeded 0%, #d5dbdb 100%) !important; */
  background: var(--gradient-light) !important;

  /* background: #fefae0!important; */
  /* background:  #f8fafc!important; */
  color: #151734 !important;
  /* font-weight: 300; */
}

body.light .list-group-item {
  background-color: #fff !important;
  color: #000;
}

body.light .list-group-item.active {
  background-color: var(--orange) !important;
  border-color: var(--orange) !important;
  color: #fff !important;
}

body.light .list-group-item.active b {
  color: #fff !important;
}

body.light .bg-black {
  background: #fff !important;
  color: #000!important;
}

body.light a:not(.btn)  {
  color: #000 !important;
}

body.light .bg-white-soft {
  background: rgba(255, 255, 255, 0.4) !important;
  color: #000 !important;
}

body.light .btn {
  /* font-weight: 500!important; */
  font-family: var(--heading-font-typeface);
  /* text-transform: uppercase; */
  font-size: 1em;
}

body.light .btn-primary {
  color: #fff !important;
}

body.light .nft-card {
  background: rgba(255, 255, 255, 1) !important;
  color: #000 !important;
  border: 1px solid #ddd !important;
}

body.light .nft-card .card-title {
  color: #000;
}


body.light .nft-card .price {
  color: #fff !important;
}

body.light .accordion,
body.light .accordion-item {
  color: #000!important;
  /* border: 1px solid #333; */
}

/* body.light .btn a { color: #fff!important;} */
body.light .btn-light {
  color: #000 !important;
}

body.light .accordion-button {
  background: #fff;
  color: #000;
  border: 0px !important;
}

body.light .accordion-button:not(.collapsed) {
  background: #fff;
  color: #000;
  border: 0px;
}

.accordion-button::after {
  filter: invert(0);
}

body.light .bicon {
  background-color: rgba(255, 255, 255, 0.7);
  color: #000;
}


body.light .navbar-toggler {
  color: #000;
}

.text-underline {
  text-decoration: underline;
}

body.light .toast-body b {
  color: #fff !important;
}


label.light.btn.btn-primary {
  background: rgba(0, 0, 0, 0.7) !important;
  color: #fff;
  opacity: 0.5;
}


label.dark.btn.btn-primary {
  background: rgba(0, 0, 0, 0.7) !important;
  color: #fff;
  opacity: 0.3;

}
.btn-group>.btn,
.btn-group>.btn-check:checked+.btn,
.btn-group>.btn-check:focus+.btn,
.btn-group>.btn:hover,
.btn-group>.btn:focus,
.btn-group>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn-check:checked+.btn,
.btn-group-vertical>.btn-check:focus+.btn,
.btn-group-vertical>.btn:hover,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn.active {
  color: var(--white) !important;
  opacity: 1 !important;
}

body.light b,
body.light strong,
body.light .bold {
  /* color: #000; */
}

body.light .toast-body,
body.light .toast-header strong {
  color: #fff !important;
}

body.light .invert {
  filter: invert(0%)
}

body.light .btn-dark .invert {
  filter: invert(100%);
}

body.light .carousel-control-prev-icon,
body.light .carousel-control-next-icon {
  filter: invert(100%);
}

.carousel.no-margin-padding .carousel-inner {
  margin: 0px 0px !important;
  padding: 0px 0px !important;
}


.modal-header {
  border: 0px;
}

.modal-content {
  background: #000;
  color: #fff;
}

.modal-header .btn-close {
  color: #fff !important;
  filter: invert(100%)
}


body.light .modal-header {
  border: 0px;
}

body.light .modal-content {
  background: linear-gradient(90deg, rgb(227, 227, 218) 0%, rgb(197, 215, 218) 100%) !important;
  color: #000;
}

body.light .modal-header .btn-close {
  color: #000 !important;
  filter: invert(0%)
}

.carousel-indicators [data-bs-target] {
  background-color: rgba(255, 255, 155, 0.5);
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

body.light .carousel-indicators [data-bs-target] {
  background-color: rgba(0, 0, 0, 0.5);
}

body.light .btn.btn-dark {
  background: #fff !important;
  color: #000 !important;
  border: 0px solid #ddd !important;
}

body.light .navbar-nav .nav-link {
  /* color: #000!important; */
}

.btn-group>.btn-check:checked+.btn,
.btn-group>.btn-check:focus+.btn,
.btn-group>.btn:hover,
.btn-group>.btn:focus,
.btn-group>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn-check:checked+.btn,
.btn-group-vertical>.btn-check:focus+.btn,
.btn-group-vertical>.btn:hover,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn.active {
  background: var(--black) !important;
  /* font-size: 90%; */
}

@media only screen and (max-width: 600px) {

  /* .container {
    padding: 0px 0px!important;
    margin: 0px 0px!important;
  }

  .row {
    padding: 0px 0px!important;
    margin: 0px 0px!important;
  }


  .row > * {
    padding: 0px 0px!important;
    margin: 0px 0px!important;
  } */


  /* .learn_btn { 
    display: none;
  } */

  .toast-container {
    margin-top: 60px !important;
    width: 100%;
    
  }

  .toast-container .toast {
    width: 100%;
  }

  /* .modal {
    top: 49px;
  } */

  /* .modal.show .modal-dialog {
    transform: none;
    width: 100%;
    margin: 0px;
  } */

  /* .modal.show .modal-dialog .modal-content {
  border-radius: 0em 0em!important;
  border: 0px;
} */


  .header {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    height: 50px !important;
    background: #000;
    margin-top: 0px;
  }

  .navbar {
    padding: 5px !important;
    margin: 0px !important;
  }

  .navbar .nav-link {
    padding: 10px 15px !important;
  }

  div#wallet_details.show {
    height: calc(100vh);
  }

  body.light .header {
    background: #fff;
  }

  .App {
    margin-top: 50px;
  }

}

@media (max-width: 767px) {
  div.main {
    padding: 0px !important;
    margin-top: -5px;
  }

  div.main .row {
    --bs-gutter-x: 0rem !important;
  }

  .rounded-2,
  .rounded-3 {
    border-radius: 0em 0em !important;
  }

  .container {
    max-width: 100% !important;
  }

  .carousel-indicators {
    display: none;
  }
}

@media (max-width: 1000px) {
  .navbar-nav {
    flex-direction: row;
  }

  .navbar-nav .nav-link {
    padding: 5px 15px;
  }
}

/* 
@media (max-width: 480px) { 

  .p-5 {
    padding: 0.2bem !important;
  }

  .ms-5{
    margin-left: 2px!important;
  }

  .mr-5{
    margin-right: 2px!important;
  }

  .btn {
    width: 100% !important;
  }

 } */



.learn_btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border: 0px;
  border-radius: 100%;
  background: var(--gradient) !important;
  transform: scale(1);
  z-index: 9999;
  text-align: center;
  padding: 0px 0px;
  margin: 0px 0px;

}

.learn_btn img {
  width: 60px;
  height: 60px;
  margin-top: -15px;
  margin-left: -10px;
}

.help_open .learn_btn:hover {
  animation-iteration-count: 0;
}

.learn_btn:hover {
  background: var(--blue)!important;
}
/* .learn_btn:hover {
    animation-name: example;
  animation-duration: 0.2s;
  animation-iteration-count: 1;
}
@keyframes example {
  from {transform: scale(3);}
  to {transform: scale(2);}
} */


.learn {
  position: fixed;
  bottom: 70px;
  right: 50px;
  width: 0%;
  height: 0px;
  z-index: 9999999;
  box-shadow: 1px 1px 14px #111;
  border-radius: 0.5rem 0.5rem;
  transition: all 0.25s ease;
  overflow: scroll;
  background: #000;
}

.learn.show {
  height: calc(100vh - 280px);
  width: 30%;
  transform: translateY(0);
}

.learn.show.expand {
  height: calc(100vh - 100px);
  width: calc(100vw - 100px);
  transform: translateY(0);
}

@media only screen and (max-width: 600px) {

  .learn {
    right: 0px
  }
  .learn_btn {
    transform: scale(1)!important;
    bottom: -10px;
    right: -10px;
  }

  .learn.show, .learn.show.expand {
    height: calc(100vh - 70px);
    width: calc(100vw - 0px);
    transform: translateY(0);
  }

  .header {
    margin-top: 0px!important;
    height: 50px!important;
  }
  
}

.pri {
  background: linear-gradient(90deg, rgb(45, 45, 45) 0%, rgb(8, 8, 8) 100%) !important;
  padding: 10px 15px;
  border-radius: 0em 0em!important;
}


body.light .learn {
  background: #fff;
  box-shadow: 1px 1px 14px rgba(0, 0, 0, 0.1);
}

body.light .pri {
  background: linear-gradient(90deg, rgb(227, 227, 218) 0%, rgb(197, 215, 218) 100%) !important;
}

.dropdown-toggle::after {
  display: none;
}

body.light .toast-header .btn-close {
  color: #fff!important;
}

body.light .border-top {
  border-color: #eee!important;
}




.timeline-container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 40px 0;
}

.timeline-container::after {
  background-color: #e17b77;
  content: '';
  position: absolute;
  left: calc(50% - 2px);
  width: 4px;
  height: 100%;
}


.timeline-item {
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  position: relative;
  margin: 10px 0;
  width: 50%;
}

.timeline-item:nth-child(odd) {
  align-self: flex-end;
  justify-content: flex-start;
  padding-left: 30px;
  padding-right: 0;
}

.timeline-item-content {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 15px;
  position: relative;
  width: 400px;
  max-width: 70%;
  text-align: right;
}

.timeline-item-content::after {
  content: ' ';
  background-color: #fff;
  box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.2);
  position: absolute;
  right: -7.5px;
  top: calc(50% - 7.5px);
  transform: rotate(45deg);
  width: 15px;
  height: 15px;
}

.timeline-item:nth-child(odd) .timeline-item-content {
  text-align: left;
  align-items: flex-start;
}

.timeline-item:nth-child(odd) .timeline-item-content::after {
  right: auto;
  left: -7.5px;
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
}

.timeline-item-content .tag {
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  top: 5px;
  left: 5px;
  letter-spacing: 1px;
  padding: 5px;
  position: absolute;
  text-transform: uppercase;
}

.timeline-item:nth-child(odd) .timeline-item-content .tag {
  left: auto;
  right: 5px;
}

.timeline-item-content time {
  color: #777;
  font-size: 12px;
  font-weight: bold;
}

.timeline-item-content p {
  font-size: 16px;
  line-height: 24px;
  margin: 15px 0;
  max-width: 250px;
}

.timeline-item-content a {
  font-size: 14px;
  font-weight: bold;
}

.timeline-item-content a::after {
  content: ' ►';
  font-size: 12px;
}

.timeline-item-content .circle {
  background-color: #fff;
  border: 3px solid #e17b77;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 10px);
  right: -40px;
  width: 20px;
  height: 20px;
  z-index: 100;
}

.timeline-item:nth-child(odd) .timeline-item-content .circle {
  right: auto;
  left: -40px;
}

@media only screen and (max-width: 1023px) {
  .timeline-item-content {
      max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .timeline-item-content,
  .timeline-item:nth-child(odd) .timeline-item-content {
      padding: 15px 10px;
      text-align: center;
      align-items: center;
  }

  .timeline-item-content .tag {
      width: calc(100% - 10px);
      text-align: center;
  }

  .timeline-item-content time {
      margin-top: 20px;
  }

  .timeline-item-content a {
      text-decoration: underline;
  }

  .timeline-item-content a::after {
      display: none;
  }
}



.title {
  font-size: 1.4em;
  font-weight: 300;
}

.bar-group .name-label {
  text-anchor: end;
  font-weight: 300;
  font-size: 1em;
  fill: #333;
}

.bar-group .value-label {
  text-anchor: end;
  fill: #fff;
  font-weight: 900;
  font-size: 0.7em;
}


.accordion-body, .accordion-item {
  background: #000!important;
}


body.light .accordion-body, body.light .accordion-item {
  background:#fff!important;
}
.process {
  /* background: #fff; */
  /* padding: 5px 0px 0px 0px; */
  /* padding: 0px 10px; */
  /* border-radius: 0.5rem 0.5rem; */
}

.process .accordion {
}

.process  .accordion-header {
  padding-left: 60px;
}

.process .accordion-item {
  position: relative;
  border: 0px solid #eee;
}

.process .accordion-body {
  padding-left: 70px;
  position: relative;
}

/* .process .accordion-body::before, .process .accordion-body::after { */
.process .accordion-item::after {
  content: "";
  position: absolute;
  background: var(--green);
  width: 2px;
  height: auto;
  left: 41px;
  top: 40px;
  bottom: 0px;
  z-index: 0;
}

.process .accordion-item::before {
  content: "";
  position: absolute;
  background: var(--green);
  width: 2px;
  height: auto;
  left: 41px;
  top: 0px;
  bottom: 0px;
  z-index: 0;
}

.process .accordion .accordion-item:first-child::before {
  background: transparent!important;
}



.process span.aicon {
  border-radius: 100% 100%;
  background: var(--gradient-green);
  border: 2px solid var(--green);
  color: #fff;
  width: 40px;
  height: 40px;
  line-height: 36px;
  text-align: center;
  /* padding: 10px 10px; */
  margin-right: 10px;
  position: absolute;
  left: -38px;
  top: 10px;
  z-index: 1;
}

.process .accordion-button.collapsed span.aicon {
  background: #000;
  color: var(--gradient-green);
}

body.light .process .accordion-button.collapsed span.aicon {
  background: #fff!important;
}

/* .process span.aicon::before {
  content: "";
  position: absolute;
  background: var(--green);
  width: 2px;
  height: auto;
  left: 17px;
  top: -15px;
  bottom: 40px;
  z-index: 3;
}

.process span.aicon::after {
  content: "";
  position: absolute;
  background: var(--green);
  width: 2px;
  height: auto;
  left: 17px;
  top: 38px;
  bottom: -38px;
  z-index: 3;
} */





/* 
span.aicon::before {
  content: "";
  position: absolute;
  background: red;
  width: 5px;
  left: 35px;
  top: 55px;
  bottom: 0px;
} */

.text-small {
  font-size: 85%;
}



/* 
.row {
  position: relative;
}

.middle.col {
  max-width: 50px!important;
  padding: 10px 10px;
  text-align: center;
}

.middle svg {
  background: #fff;
  border: 5px solid green;
  padding: 10px 10px;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  position: absolute;
  z-index: 1;
  margin-left: -15px;
}

.middle.col::after {
  content: "";
  position: absolute;
  width: 5px;
  background: green;
  height: 100%;
  bottom: 0px;
  margin-left: 8px;
  z-index: 0;
}

.right {
} */

/* .text-dark {
  color: #fff!important;
} */

body.light .text-dark {
  color: #000!important;
}


.tooltip{
  /* background: #fff!important; */
  margin-top: 15px;
  margin-left: -15px;
  border-radius: 0.5rem 0.5rem;
}

.tooltip-inner {
  max-width: 296px!important;
  padding: 10px 10px;
  background: #fff;
}

.tooltip .tooltip-arrow::before {
  border-bottom-color: #fff!important;
  border-width: 10px;
}

.alert-bg-danger {
  border-left: 3px solid var(--red);
}

.alert-bg-info {
  border-left: 3px solid var(--black);
}

.alert-bg-warning {
  border-left: 3px solid var(--orange);
}

.toast-container.hidden { 
  /* width: 0px;  */
  right: -500px!important;

  transition: all 0.15s ease-out;
}


.toast-container {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  padding: 10px 10px;
  right: 0px!important;
  transition: all 0.15s ease-out;

}


.header {
  margin-top: 10px;
}



body.goerli {
  --orange: #3498db;
  --pink: rgba(52, 152, 219,.9);
  --blue: linear-gradient(135deg,#3c6eff,#6fa7ff);
  --gradient: linear-gradient(97.3deg, var(--pink) 0%, var(--orange) 100%);
}


body.light {
  background: #fff!important;
}

/* body.light .bg-white-soft, body.light .bg-black {
  background: #eee!important;
} */

body.dark {
  background: #000!important;
}

/* body.dark .bg-white-soft, body.dark .bg-black {
  background: #333!important;
} */


body.polygon {
  --orange: rgb(139,67,237);
  --pink: rgba(139,67,237, 0.7);
  --blue: linear-gradient(135deg,#3c6eff,#6fa7ff);
  --gradient: linear-gradient(97.3deg, var(--pink) 0%, var(--orange) 100%);
}

body.dark.polygon
/* , body.dark.polygon .bg-black */
/* body.dark.polygon .accordion-button, body.dark.polygon .accordion-body, body.dark.polygon .accordion-item  */
{
  /* background-color: #140035!important; */
}

body.dark.goerli
/* , body.dark.polygon .bg-black */
/* body.dark.polygon .accordion-button, body.dark.polygon .accordion-body, body.dark.polygon .accordion-item  */
{
  /* background-color: #3498db!important; */
}

body.dark.polygon .image {
  /* background-color: #fff!important; */
}

body.light.polygon, body.light.polygon .bg-black
/* , body.light.polygon .accordion-button, body.light.polygon .accordion-body, body.light.polygon .accordion-item  */
{
  /* background: rgba(130,71,229,.1)!important; */
}


.sticky {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  top: 10px;
  z-index: 100;
}


.invert {
  filter: invert(1);
}

body.light .invert {
  filter: invert(0);
}


.img-thumbnail {
  background: #333;
  border-color: rgba(0, 0, 0, 0.8);
}

body.light .img-thumbnail {
  background: #f9f9f9;
  border-color: rgba(0, 0, 0, 0.1);
}


/* .bg-image {
  display: none;
}
.bg-white-soft {
  background: #ecf0f9!important;
} */


.btn-sm {
  padding: 2px 10px;
  font-size: 0.9em!important;
}